import * as React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { variant } from 'styled-system'
import { useHistory } from 'react-router-dom';
import theme from '../../assets/css/theme';
import {
  ICampaignReDownloadData,
  ICampaignsCard,
} from '../../modules/types';
import {
  Button,
  DateValue,
  SizeValue,
  CampaignBoxTitle,
  CampaignRevenueSize,
  CampaignIncrementalRevenueSize,
  CampaignTargetedOpportunitySize,
} from '../../components/BoxComponents';
import { ProportionTotal } from '../../components/Charts';
import { DownloadIcon } from '../../components/Icons';
import { InfoChip } from '../../components/Chip';
import {
  GRID_SIZE_2,
  GRID_SIZE_4,
  GRID_SIZE_12,
} from '../../config';
import EditDatesButtons from './EditDates'

const MAX_TIME_ELAPSED = 100

interface IHeaderCardElementProps {
  isShowingEditDatesButtons: boolean
}

interface IProps extends ICampaignsCard {
  onDownloadClick: (payload: ICampaignReDownloadData) => void;
}

interface IMetricsProps {
  revenue: number;
  incremental_revenue: number;
  targeted_opportunity: number;
}

interface IStatusesProps {
  completed: boolean;
  is_creation_complete: boolean;
  start_date: string;
}

const CampaignCard = (props: IProps) => {
  const history = useHistory()
  const onDownloadClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    props.onDownloadClick({
      campaign_id: props.campaign_id,
    });
  }

  const goToCampaignPage = () => {
   return history.push(`/campaign/${props.campaign_id}`)
  }

  const shouldShowEditDatesButtons = !props.completed && props.time_elapsed < MAX_TIME_ELAPSED
  const revenue = props.control_revenue + props.campaign_revenue
  const expected_revenue = props.control_expected_sales
  let incremental_revenue = revenue - expected_revenue
  if (incremental_revenue < 0) {
    incremental_revenue = 0
  }

  const shouldShowDownloadCSV = !props.completed && props.is_creation_complete && !!props.csv_url

  return (
    <CampaignCardContainer onClick={goToCampaignPage}>
      <HeaderCardElement container={true} item={true} xs={GRID_SIZE_12} isShowingEditDatesButtons={shouldShowEditDatesButtons}>
        <TitleContainer>
          <CampaignBoxTitle title={props.name}/>
          <EditDatesButtons
            shouldShow={shouldShowEditDatesButtons}
            campaignId={props.campaign_id}
            hasStarted={!!props.time_elapsed}
            startDate={props.start_date}
            endDate={props.end_date}
          />
        </TitleContainer>
        <ProportionTotalWrapper>
          <ProportionTotal
            label={`${props.time_elapsed}% of time elapsed`}
            value={props.time_elapsed}
          />
        </ProportionTotalWrapper>
      </HeaderCardElement>

      <Grid container={true} item={true} xs={GRID_SIZE_12}>
        <CardColumn container={true}
          item={true}
          lg={GRID_SIZE_4}
          md={GRID_SIZE_12}
          sm={GRID_SIZE_12}
          xs={GRID_SIZE_12}
          justifyContent="flex-start"
          alignItems="center"
        >
          <SizeValueWrapper>
            <SizeValueStyled value={props.person_count} />
            { !!shouldShowDownloadCSV && (
              <DownloadButton to="#" onClick={onDownloadClick}>
                <DownloadIcon />
              </DownloadButton>
            )}
            <CampaignStatuses
              completed={props.completed}
              is_creation_complete={props.is_creation_complete}
              start_date={props.start_date}
            />

          </SizeValueWrapper>
        </CardColumn>
        <CardColumn container={true}
              item={true}
              lg={GRID_SIZE_2}
              md={GRID_SIZE_12}
              sm={GRID_SIZE_12}
              xs={GRID_SIZE_12}
              justifyContent="space-between"
              alignItems="center"
        >
          <DateValue date={props.start_date} label="Campaign Launch" />
          <DateValue date={props.end_date} label="Campaign End" />
        </CardColumn>
        <CampaignMetrics
          revenue={revenue}
          incremental_revenue={incremental_revenue}
          targeted_opportunity={props.targeted_opportunity}
        />
      </Grid>
    </CampaignCardContainer>
  );
}

const CampaignStatuses = (props: IStatusesProps) => {
  const shouldShowCreationInProgressMsg = !props.completed && !props.is_creation_complete
  const shouldShowHasNotStarted = !shouldShowCreationInProgressMsg && props.start_date && new Date(props.start_date) > new Date()

  return (
    <>
      { !!shouldShowCreationInProgressMsg && (
        <ChipContainer>
          <InfoChip
            size="small"
            label="Creation in progress"
          />
        </ChipContainer>
      )}
      { !!shouldShowHasNotStarted && (
        <ChipContainer>
          <InfoChip
            size="small"
            label="Campaign has not started"
          />
        </ChipContainer>
      )}
    </>
  )
}

const CampaignMetrics = (props: IMetricsProps) => {
  return (
    <>
      <CardRevenueColumnForWithIcon container={true}
        item={true}
        lg={GRID_SIZE_2}
        md={GRID_SIZE_12}
        sm={GRID_SIZE_12}
        xs={GRID_SIZE_12}
        alignItems="center"
      >
        <CampaignTargetedOpportunitySize value={props.targeted_opportunity} spaceSize="1rem" />
      </CardRevenueColumnForWithIcon>

      <CardRevenueColumnForWithIcon container={true}
        item={true}
        lg={GRID_SIZE_2}
        md={GRID_SIZE_12}
        sm={GRID_SIZE_12}
        xs={GRID_SIZE_12}
        alignItems="center"
      >
        <CampaignIncrementalRevenueSize value={props.incremental_revenue} spaceSize="1.125rem" />
      </CardRevenueColumnForWithIcon>

      <CardRevenueColumnForWithIcon container={true}
        item={true}
        lg={GRID_SIZE_2}
        md={GRID_SIZE_12}
        sm={GRID_SIZE_12}
        xs={GRID_SIZE_12}
        alignItems="center"
      >
        <CampaignRevenueSizeStyled value={props.revenue} spaceSize="0.9375rem" />
      </CardRevenueColumnForWithIcon>
    </>
  )
}

const CampaignCardContainer = styled(Grid)`
  background-color: ${theme.colorWhite};
  box-shadow: ${theme.boxShadow};
  padding: 1.25rem 1.688rem 1.313rem 1.063rem;
  &:hover{
    box-shadow: ${theme.boxShadowDarker};
    cursor: pointer;
    }
 `;

 const HeaderCardElement = styled(Grid)<IHeaderCardElementProps>`
  && {
    flex-direction: column;
    @media ${theme.media.tablet} {
      flex-direction: column;
    }
    @media ${theme.media.laptopL} {

      ${() => variant({
        prop: 'isShowingEditDatesButtons',
        variants: {
          false: {
            flexDirection: 'row',
            justifyContent: 'space-between'
          }
        }
      })}
    }
  }
`;

const CardColumn = styled(Grid)`
  && {
    word-break: break-word;
    padding: ${GRID_SIZE_2}px;
  }
`;

const CardRevenueColumnForWithIcon = styled(CardColumn)`
  && {
    justify-content: flex-start;

    @media ${theme.media.laptopL} {
        justify-content: flex-end;
      }
  }
`;

const SizeValueStyled = styled(SizeValue)`
&& {
  font-family: ${theme.ffSansProRegular};
  word-break: initial;
}
`;

const SizeValueWrapper = styled.div`
display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.125rem;
  word-break: initial;
  & > div {
    font-family: ${theme.ffSansProRegular};
    word-break: initial;
  }
`;

const ProportionTotalWrapper = styled.div`
  & > div {
    min-width: 15rem;
  }
`;

const DownloadButton = styled(Button)`
  padding: 0 1rem;
  background-color: transparent;
`;

const CampaignRevenueSizeStyled = styled(CampaignRevenueSize)`
align-self: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ChipContainer = styled.div`
  margin-left: 12px;
  margin-top: 8px;
`

export default CampaignCard;
