import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { campaignUpdateDatesRequest } from '../../modules/actions';
import { Button } from '../../components/Form'
import { Modal } from '../../components'
import theme from '../../assets/css/theme';
import { useWindowSize } from '../../utils/hooks'
import { DATE_FORMAT } from '../../config/datetime';

const VERTICAL_WINDOW_WIDTH_SIZE = 1024
const END_DATE_TYPE = 'endDate'
const SUCCESS_MESSAGE_TIMEOUT = 6000

interface IProps {
  shouldShow: boolean
  handleUpdateDates: typeof campaignUpdateDatesRequest;
  campaignId: string;
  hasStarted: boolean;
  startDate: string;
  endDate: string;
}

interface IOnDatesChange {
  startDate: Moment | null;
  endDate: Moment | null;
}

type TFocusedDateType = 'startDate' | 'endDate';
type TOrientationType = 'vertical' | 'horizontal'

const EditDatesButtons = ({ shouldShow, campaignId, hasStarted, handleUpdateDates, ...props }: IProps) => {
  const [isEditDatesModalOpen, setIsEditDatesModalOpen] = useState(false)
  const [isEditEndDateModalOpen, setIsEditEndDateModalOpen] = useState(false)
  const [shouldShowEndNowSuccessMessage, setShouldShowEndNowSuccessMessage] = useState(false)
  const [focusedDateType, setFocusedDateType] = useState<TFocusedDateType | null>(null)
  const [startDate, setStartDate] = useState<Moment | string | null>(props.startDate)
  const [endDate, setEndDate] = useState<Moment | string | null>(props.endDate)
  const { width: windowWidth } = useWindowSize()

  const onDatesChange = (newValues: IOnDatesChange) => {
    if (startDate !== newValues.startDate) {
      setStartDate(newValues.startDate)
    }

    if (endDate !== newValues.endDate) {
      setEndDate(newValues.endDate)
    }
  }

  const onEndDateChange = (newEndDate: Moment | null) => setEndDate(newEndDate)

  const closeEditDatesModal = useCallback(() => setIsEditDatesModalOpen(false), [])
  const openEditDatesModal = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setIsEditDatesModalOpen(true)
  }, [])
  const closeEditEndDateModal = useCallback(() => {
    setIsEditEndDateModalOpen(false)
  }, [])
  const openEditEndDateModal = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setIsEditEndDateModalOpen(true)
  }, [])
  const closeSuccessMessage = useCallback(() => setShouldShowEndNowSuccessMessage(false), [])

  const renderDatesCalendarInfo = useCallback(() => <DayPickerTitle>Select Campaign Date Range</DayPickerTitle>, [])
  const renderEndDateCalendarInfo = useCallback(() => <DayPickerTitle>Select Campaign End Date</DayPickerTitle>, [])

  const setIsEndDateFocused = useCallback(({ focused }) => {
    if (focused && focusedDateType !== END_DATE_TYPE) {
      setFocusedDateType(END_DATE_TYPE)
    } else if (!focused) {
      setFocusedDateType(null)
    }
  }, [focusedDateType])

  let datePickerOrientation: TOrientationType = 'horizontal'
  if (windowWidth && windowWidth < VERTICAL_WINDOW_WIDTH_SIZE) {
    datePickerOrientation = 'vertical'
  }

  const handleSave = (overrideEndDate: Moment | null = null) => {
    closeEditDatesModal()
    closeEditEndDateModal()

    if (!!startDate && !!endDate) {
      let endDateVal = moment.isMoment(overrideEndDate) ? overrideEndDate : endDate
      if (typeof endDateVal === 'string') {
        endDateVal = moment(endDateVal)
      }
      // if it's a string we stil need to format it as it won't have the correct format if it came from props
      const start_date = typeof startDate === 'string' ? moment(startDate).utc().format(DATE_FORMAT) : startDate.utc().format(DATE_FORMAT)
      const end_date = endDateVal.utc().format(DATE_FORMAT)

      handleUpdateDates({
        campaign_id: campaignId,
        start_date,
        end_date,
      })

      if (moment().diff(endDateVal, 'days') === 0) {
        setShouldShowEndNowSuccessMessage(true)
      }
    }
  }

  const handleFinishCampaignNow = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    const now = moment()
    handleSave(now)
  }

  const formattedStartDate = typeof startDate === 'string' ? moment(startDate) : startDate
  const formattedEndDate = typeof endDate === 'string' ? moment(endDate) : endDate
  const handleClickModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  }

  return (
    <>
      { shouldShow && (
        <Container>
          {hasStarted ? (
            <>
              <LeftButtonContainer>
                <SmallButton onClick={handleFinishCampaignNow}>Finish campaign now</SmallButton>
              </LeftButtonContainer>
              <SmallButton onClick={openEditEndDateModal}>Change end date</SmallButton>
            </>
          ) : (
            <SmallButton onClick={openEditDatesModal}>Change dates</SmallButton>
          )}
          
          <Modal isOpen={isEditDatesModalOpen} handleClose={closeEditDatesModal}>
            <EditDatesContainer onClick={handleClickModal}>
              <ModalTitle>Edit campaign dates</ModalTitle>
                <CampaignDateRangePickerContainer>
                  <DateRangePicker
                    startDate={formattedStartDate}
                    startDateId="campaign_start_date_id"
                    endDate={formattedEndDate}
                    endDateId="campaign_end_date_id"
                    onDatesChange={onDatesChange}
                    focusedInput={focusedDateType}
                    onFocusChange={setFocusedDateType}
                    customArrowIcon={<CustomArrowIcon>to</CustomArrowIcon>}
                    orientation={datePickerOrientation}
                    renderCalendarInfo={renderDatesCalendarInfo}
                    displayFormat="DD-MMM-YY"
                  />
                  <SaveButton onClick={handleSave}>Save</SaveButton>
              </CampaignDateRangePickerContainer>
            </EditDatesContainer>
          </Modal>
          
          <Modal isOpen={isEditEndDateModalOpen} handleClose={closeEditEndDateModal}>
            <EditDatesContainer onClick={handleClickModal}>
              <ModalTitle>Edit campaign end date</ModalTitle>
                <CampaignDateRangePickerContainer>
                  <SingleDatePicker
                    date={formattedEndDate}
                    id="campaign_end_date_id"
                    onDateChange={onEndDateChange}
                    focused={focusedDateType === END_DATE_TYPE}
                    onFocusChange={setIsEndDateFocused}
                    orientation={datePickerOrientation}
                    renderCalendarInfo={renderEndDateCalendarInfo}
                    displayFormat="DD-MMM-YY"
                  />
                  <SaveButton onClick={handleSave}>Save</SaveButton>
              </CampaignDateRangePickerContainer>
            </EditDatesContainer>
          </Modal>
        </Container>
      )}

      <Snackbar open={shouldShowEndNowSuccessMessage} autoHideDuration={SUCCESS_MESSAGE_TIMEOUT} onClose={closeSuccessMessage}>
        <MuiAlert onClose={closeSuccessMessage} severity="success">
          This campaign will end at midnight.
        </MuiAlert>
      </Snackbar>
    </>
  )
}

const mapDispatchToProps = {
  handleUpdateDates: campaignUpdateDatesRequest,
};

export default connect(null, mapDispatchToProps)(EditDatesButtons)

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const LeftButtonContainer = styled.div`
  margin-right: 16px;
`

const SmallButton = styled(Button)`
  && {
    background-color: ${theme.colorDarkBlue};
    padding: 0.5625rem 1.2rem 0.5rem 1.2rem;
    font-family: ${theme.ffSansProRegular};
    font-weight: normal;
    font-stretch: normal;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    width: auto;
    height: auto;
  }
`;

const EditDatesContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 4%);
  padding: 1.25rem 1.688rem 1.313rem 1.063rem;
  border-radius: 4px;
  width: 540px;
`

const ModalTitle = styled.div`
  font-family: ${theme.ffInterRegular};
  font-size: 1.125rem;
  line-height: 1.39;
  color: ${theme.titleColor};
`;

const CampaignDateRangePickerContainer = styled.div`
  margin-top: 1.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SaveButton = styled(SmallButton)`
  margin-top: 1.25rem;
`

const CustomArrowIcon = styled.span`
  font-family: ${theme.ffSansProRegular};
  font-size: 0.8125rem;
  font-weight: normal;
  color: ${theme.colorDarkGray};
  padding: 8px 11px 6px;
`;

const DayPickerTitle = styled.div`
  font-family: ${theme.ffSansProRegular};
  font-size: 1.125rem;
  color: ${theme.colorDarkGray};
  padding: 20px;
`;